const config = {
  "broker": "1",
  "name": "Hindi",
  "registrationBundle": "hindi",
  "defaultCountryName": "India",
  "defaultCountryCode": "IN",
  "fxgeoip_code": "IN",
  languageCode: 'hi',
  languageName:'English',
  spokenLanguageCode:'en',
  direction:'ltr',
  footerDisclaimer:'767'
};
module.exports = config;